body,
input,
label {
  font-family: "Montserrat", sans-serif;
  color: #000;
}

.np {
  padding: 0 !important;
}

h1 {
  font-weight: 900;
  color: #000;
}

p {
  font-weight: 600;
  font-size: 20px;
  color: #000;
}

a {
  font-weight: 600;
  font-size: 20px;
  color: #000;
}

a:hover {
  color: #000;
}

strong {
  font-weight: 900;
  color: #000;
}

.home {
  background-color: #ffcf00;
}

.home-header {
  text-align: left;
  padding: 31px 46px;
  /* margin-bottom: 80px; */
}

.matcha-balance-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #000;
  font-size: 22px;
  float: right;
  padding-top: 26px;
  padding-left: 10px;
}

.matcha-balance-data {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 28px;
  float: right;
  padding-top: 20px;
}

.home-header img {
  width: 630px;
}

.home-body-header img {
  width: 330px;
}

.home-component-navi {
  text-align: left;
  margin-bottom: 150px;
}

.home-component-navi a {
  font-size: 18px;
}

.home-body {
  /* max-width: 650px; */
  margin: 0 auto;
  padding-top: 20px;
  /* padding-bottom: 120px; */
}

.home-body-left {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 1000px;
}

.home-body-right {
  max-width: 650px;
  padding-top: 20px;
  margin: 0 auto;
}

.home-body-header {
  margin-bottom: 40px;
}

.home-body-header h1 {
  margin-bottom: 30px;
}

.home-body-inner {
  /* border: 6px solid #000; */
  border-radius: 35px;
  padding: 40px;
}

.home-body-form {
  max-width: 350px;
  margin: 0 auto;
}

.home-body-form form {
  margin-bottom: 20px;
}

.home-body-form input {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  background: transparent;
  border: 2px solid black;
  border-radius: 30px;
}

.form-control:focus {
  border-color: #000 !important;
}

.home-body-form input::placeholder {
  color: #000;
}

.text-muted {
  color: #000;
  font-size: 12px;
}

.home-body-form-status {
  margin-bottom: 20px;
}

.home-body-form-status-label {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #000;
  font-size: 18px;
}

.home-body-form-status-data-whitelisted {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #518636;
  font-size: 24px;
}

.home-header-btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #ffcf00;
  font-size: 24px;
  background-color: #000;
  border-radius: 30px;
  padding: 12px 20px;
  margin-top: 8px;
  margin-bottom: 20px;
  float: right;
}

.home-header-btn:hover {
  color: #000;
  background-color: #ffcf00;
}

.home-mint-btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #ffcf00;
  font-size: 24px;
  background-color: #000;
  border-radius: 30px;
  padding: 12px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-mint-btn:hover {
  color: #000;
  background-color: #ffcf00;
}

.btn-counter {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #ffcf00;
  font-size: 24px;
  background-color: #000;
  border-radius: 30px;
  padding: 12px 20px;
  display: inline-flex;
  margin-left: 30px;
  margin-right: 30px;
}

.home-body-counter {
  width: 100%;
}

.counter-data {
  display: inline-flex;
}

.counter-data p {
  font-size: 28px;
}

.loading-gif {
  width: 30px;
}

.home-matcha {
  background-color: #ffbddb;
  padding: 80px 80px;
}

.matcha-section {
  padding-top: 50px;
}

.home-matcha-left p {
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-matcha p {
  margin-bottom: 20px;
}

.matcha-section-balance {
  display: inline-block;
}

.calc-apr {
  font-size: 30px;
}

.matcha-section-box {
  margin-top: 35px;
}

.matcha-section-balance-title {}

.matcha-section-ticker-apr {
  padding-top: 5px;
  padding-left: 5px;
  font-size: 16px;
  float: right;
}

.matcha-section-ticker {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #000;
  font-size: 22px;
  float: right;
  padding-top: 5px;
  padding-left: 10px;
}

.matcha-section-balance-data {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #518636;
  font-size: 28px;
  float: right;
  /* padding-top: 20px; */
}

.matcha-claim-btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #ffbddb;
  font-size: 24px;
  background-color: #000;
  border-radius: 30px;
  padding: 12px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.matcha-claim-btn:hover {
  color: #000;
  background-color: #ffbddb;
}

.home-yt {
  padding: 80px;
}

.footer {
  background-color: #000;
  min-height: 200px;
  padding-top: 240px;
  padding-bottom: 240px;
}

.footer-navi {
  margin-bottom: 30px;
}

.footer-navi a {
  color: #ffcf00;
  padding: 20px;
  text-decoration: underline;
  font-weight: 800;
  font-size: 40px;
}

.footer-made-by p {
  color: #ffcf00;
  font-weight: 300;
}

.footer-made-by p a {
  color: #ffcf00;
  font-size: 18px;
  text-decoration: underline;
}

button[disabled], html input[disabled] {
  cursor: default;
  background: transparent;
  color: black;
  border: 1px solid;
}

@media screen and (max-width: 992px) {
  .home-yt iframe {
    width: auto;
  }

  .footer-navi a {
    display: block;
  }

  .home-header {
    text-align: center;
  }

  .home-header img {
    width: 300px;
    margin-bottom: 20px;
}

  .home-header-btn,
  .home-body-left {
    display: none;
  }

  .home-component-navi {
    text-align: center;
    margin-bottom: 0px;
  }

  .home-matcha {
    padding: 80px 20px;
  }

  .home-yt {
    padding: 0px;
  }

  .matcha-balance-header {
    padding-left: 0px;
    float: revert;
  }

  .matcha-balance-data {
    padding-top: 0px;
    float: revert;
  }
}